import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { PriceQuotation } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getPagination = (state: State): IPagination => state.pagination;

export const selectPriceQuotationState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('priceQuotations');

export const selectAllPriceQuotationsItems: (
  state: object,
) => PriceQuotation[] = featureAdapter.getSelectors(
  selectPriceQuotationState,
).selectAll;

export const selectPriceQuotationById = (id: string) =>
  createSelector(
    selectAllPriceQuotationsItems,
    (priceQuotations: PriceQuotation[]) => {
      if (priceQuotations) {
        return priceQuotations.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectPriceQuotationsError: MemoizedSelector<object, any> =
  createSelector(selectPriceQuotationState, getError);

export const selectPriceQuotationsIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectPriceQuotationState, getIsLoading);

export const selectPriceQuotationsPagination: MemoizedSelector<
  object,
  IPagination
> = createSelector(selectPriceQuotationState, getPagination);

export const selectExportID = createSelector(
  selectPriceQuotationState,
  ({ exportID }) => exportID,
);
