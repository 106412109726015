import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PriceQuotationsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';
import { IResponseSuccess } from '../../core/models/response-sucess.model';

@Injectable()
export class PriceQuotationsStoreEffects {
  constructor(
    private dataService: PriceQuotationsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { page, status, properties, filters } = action.payload;
        return this.dataService.load(page, properties, filters, status).pipe(
          map(
            ({ data, meta }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
                pagination: meta.pagination,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  exportRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.ExportRequestAction>(
        featureActions.ActionTypes.EXPORT_REQUEST,
      ),
      switchMap(({ payload: { searchParams } }) =>
        this.dataService.export(searchParams).pipe(
          map(
            ({ data }: IResponseSuccess) =>
              new featureActions.ExportSuccessAction({
                exportID: data.export_ids[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.ExportFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap((action: featureActions.DeleteRequestAction) =>
        this.dataService.delete(action.payload.priceQuotationId).pipe(
          map(() => {
            const { priceQuotationId, avoidRedirect } = action.payload;
            if (!avoidRedirect) {
              this.router.navigate(['/price-quotations']);
            }
            return new featureActions.DeleteSuccessAction({
              priceQuotationId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.DeleteFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  updateRoomRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRoomRequestAction>(
        featureActions.ActionTypes.UPDATE_ROOM_REQUEST,
      ),
      switchMap(
        ({
          payload: {
            reservation_quote_option_acc_id,
            reservation_quote_id,
            reservation_quote_option_id,
            accommodation_tableau_number_id,
            accommodation_id,
          },
        }: featureActions.UpdateRoomRequestAction) =>
          this.dataService
            .updateRoomPriceList(
              reservation_quote_option_acc_id,
              reservation_quote_id,
              reservation_quote_option_id,
              accommodation_tableau_number_id,
              accommodation_id,
            )
            .pipe(
              map(() => {
                this.notifications.updateSuccess('quotes');
                return new featureActions.UpdateRoomSuccessAction({
                  noMerge: true,
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);

                return of(
                  new featureActions.UpdateRoomFailureAction({ error }),
                );
              }),
            ),
      ),
    ),
  );
}
