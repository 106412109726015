import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PriceQuotation } from '../../models';

export const featureAdapter: EntityAdapter<PriceQuotation> =
  createEntityAdapter<PriceQuotation>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<PriceQuotation> {
  isLoading?: boolean;
  error?: any;
  exportID?: number;
  pagination?: IPagination;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  exportID: null,
});
